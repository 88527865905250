
import logo from "../images/NOS-Logo-Horizontal-Color-Black.svg"
import loading from "../images/Rolling-1s-200px.svg";
import left from "../images/left.svg";
import right from "../images/right.svg";
import Upload from "./Upload";
import Row from "./Row";
import Footer from "./Footer";
import { Link, useNavigate, useParams } from 'react-router-dom';
import React, { useState, useEffect, useRef } from "react";
import download from "../images/download.svg"
import { Document, Page, Text, View, Image, pdf, StyleSheet } from '@react-pdf/renderer';
import { saveAs } from 'file-saver';
import jwtDecode from "jwt-decode";
import { useDataContext } from './DataContext';

function Report() {
  const fullscreenPhotoRef = useRef(null);

  const navigate = useNavigate();
  const { pathname } = window.location;
  let reportId = pathname.substring(4);
  const apiKey = process.env.REACT_APP_API_KEY

  const { globalFilteredData } = useDataContext()
  
  const getPreviousReportData = () => {
    const index = globalFilteredData.findIndex(item => item.id === reportId);
    let previousIndex = index + 1
    let previousUUID = globalFilteredData[previousIndex].id
    // console.log(previousUUID)

    fetch(`https://nosapi.herokuapp.com/v1/customfields/?id=${previousUUID}`, {
      method: 'GET',
      headers: {
        'x-api-key': apiKey
      },
    })
    .then(response => {
      if (response.status === 204) {
        console.log('API returned a 204 response but thats ok'); // Handle the 404 response
        // You can choose to proceed with setting empty values or handle it in another way
        return response;
      }
      if (!response.ok) {
        throw new Error('Network response was not ok');
      }
      return response.json();
    })
    .then(data => {
      // Process the data from the fetch call
  
      // ...
      // console.log(data);
      setReportData({
        date: data.date,
        name: data.sender,
        owner: data.owner,
        deliveryNumber: data.deliveryno,
        lotNumber: data.lotno,
        depot: data.depot,
        stoneGlassMetals: data.stoneglassmetal,
        rottenBerriesMold: data.rottenberriesmold,
        otherComments: data.comments
      });
  
      // Update state or perform other actions
    })
    .catch(error => {
      setIsError(true);
      console.error('There was a problem with the fetch operation:', error);
    });
  };
  
  const [ isLoading, setIsLoading ] = useState(true)
  const [ analysis, setAnalysis ] = useState([])
  const { id } = useParams();
  const [ currentImageIndex, setImageIndex ] = useState(0)
  const [ images, setImages ] = useState([])
  const [ isError, setIsError ] = useState(false);
  const [ isAuth, setIsAuth ] = useState(false);
  const [ isClicked, setIsClicked ] = useState(false);
  const [ isRotated, setIsRotated ] = useState(false);
  const [ reportData, setReportData ] = useState({
    date: '',
    name: '',
    owner: '',
    deliveryNumber: '',
    lotNumber: '',
    depot: '',
    stoneGlassMetals: '',
    rottenBerriesMold: '',
    otherComments: '',
    manualBl: '',
    manualLi: '',
    manualUr: '',
    manualWa: ''
  });
  const [isSaved, setIsSaved] = useState(false);

  function handleResize() {
    if (window.innerHeight > window.innerWidth) {
      setIsRotated(true);
    } else {
      setIsRotated(false);
    }
    if (fullscreenPhotoRef.current) {
      const width = window.getComputedStyle(fullscreenPhotoRef.current).width;
      const table = document.getElementById("fullscreen-table");
      table.style.width = width;
    }
  }

  useEffect(() => {
    window.addEventListener("resize", handleResize);
    handleResize(); // Set the initial width of the table

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  useEffect(()=>{
    setImageIndex(0);
    fetch('https://nosapi.herokuapp.com/v1/download/', {
      method: 'GET',
      headers: {
        'x-api-key': apiKey
      },
    })
    .then(response => {
      if (!response.ok) {
        setIsError(true);
        throw new Error('Network response was not ok');
      }
      return response.json();
    })
    .then(data => {
      const object = data.find((obj) => obj.id === reportId);
      auth(object.customerId);
      setAnalysis(object);
      const jsonString = object.cloudinaryBlob.replace(/'/g, '"');
      const cloudinaryBlob = JSON.parse(jsonString);
      let originalImg = cloudinaryBlob.original.secure_url
      let blueImg = cloudinaryBlob.blueberries.secure_url
      let lingonImg = cloudinaryBlob.lingon.secure_url
      let wasteImg = cloudinaryBlob.waste.secure_url
      let unripeImg = cloudinaryBlob.unripe.secure_url
      if (analysis.berry === "lingon") {
        setImages([originalImg, lingonImg, blueImg, wasteImg, unripeImg])
      } else {
        setImages([originalImg, blueImg, lingonImg, wasteImg, unripeImg])
      }
      if (analysis.date !== undefined) setReportData({...reportData, date: convertDate(analysis.date)})
    })
    .then(() => {
      setIsLoading(false);
    })
    .catch(error => {
      setIsError(true);
      console.error('There was a problem with the fetch operation:', error);
    });
  }, [apiKey, analysis.berry, reportId, id])


  useEffect(() => {
    fetch(`https://nosapi.herokuapp.com/v1/customfields/?id=${reportId}`, {
      method: 'GET',
      headers: {
        'x-api-key': apiKey
      },
    })
    .then(response => {
      if (response.status === 204) {
        console.log('API returned a 204 response but thats ok'); // Handle the 404 response
        // You can choose to proceed with setting empty values or handle it in another way
        return response;
      }
      if (!response.ok) {
        throw new Error('Network response was not ok');
      }
      return response.json();
    })
    .then(data => {
      // Process the data from the fetch call
  
      // ...
      // console.log(data);
      setReportData({
        date: data.date,
        name: data.sender,
        owner: data.owner,
        deliveryNumber: data.deliveryno,
        lotNumber: data.lotno,
        depot: data.depot,
        stoneGlassMetals: data.stoneglassmetal,
        rottenBerriesMold: data.rottenberriesmold,
        otherComments: data.comments,
        manualBl: data.manualBL_Wpcents,
        manualLi: data.manualLI_Wpcents,
        manualUr: data.manualUR_Wpcents,
        manualWa: data.manualWA_Wpcents
      });
  
      // Update state or perform other actions
    })
    .catch(error => {
      setIsError(true);
      console.error('There was a problem with the fetch operation:', error);
    });
  }, [apiKey, reportId]); // Adjust the dependencies as needed

  useEffect(() => {
    // Reset isSaved to false whenever reportData changes
    setIsSaved(false);
  }, [reportData]);

  

  const handleSave = () => {
    const postData = {
      id: reportId,
      date: reportData.date || '',
      sender: reportData.name || '',
      owner: reportData.owner || '',
      depot: reportData.depot || '',
      deliveryno: reportData.deliveryNumber || '',
      lotno: reportData.lotNumber || '',
      comments: reportData.otherComments || '',
      stoneglassmetal: reportData.stoneGlassMetals || '',
      rottenberriesmold: reportData.rottenBerriesMold || '',
      manualBL_Wpcents: reportData.manualBl || 0,
      manualLI_Wpcents: reportData.manualLi || 0,
      manualUR_Wpcents: reportData.manualUr || 0,
      manualWA_Wpcents: reportData.manualWa || 0,
      manualBL_grams: 0,
      manualLI_grams: 0,
      manualUR_grams: 0,
      manualWA_grams: 0,
      };

    fetch(`https://nosapi.herokuapp.com/v1/customfields/`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'x-api-key': apiKey,
      },
      body: JSON.stringify(postData),
    })
    .then(response => {
      if (!response.ok) {
        throw new Error('Network response was not ok');
      }
      // Handle success, if needed
      // console.log('Data saved successfully');
      // console.log(response);
      setIsSaved(true);
    })
    .catch(error => {
      setIsError(true);
      console.error('There was a problem with the fetch operation:', error);
    });
  };


  const MyPDF = ({ data, analysis }) => {
    if (data.name === "") data.name = " ";
    if (data.date === "") data.date = " ";
    if (data.owner === "") data.owner = " ";
    if (data.deliveryNumber === "") data.deliveryNumber = " ";
    if (data.lotNumber === "") data.lotNumber = " ";
    if (data.depot === "") data.depot = " ";
    if (data.stoneGlassMetals === "") data.stoneGlassMetals = " ";
    if (data.rottenBerriesMold === "") data.rottenBerriesMold = " ";
    if (data.otherComments === "") data.otherComments = " ";
    if (data.manualBl === "") data.manualBl = " ";
    if (data.manualLi === "") data.manualLi = " ";
    if (data.manualUr === "") data.manualUr = " ";
    if (data.manualWa === "") data.manualWa = " ";
    if (data.manualBL_grams === "") data.manualBL_grams = " ";
    if (data.manualLI_grams === "") data.manualLI_grams = " ";
    if (data.manualUR_grams === "") data.manualUR_grams = " ";
    if (data.manualWA_grams === "") data.manualWA_grams = " ";

    function detectBerry(analysis) {
        let berry
        analysis.lingon > analysis.blueberries ? berry="Lingonberries" : berry="Blueberries"
        return berry
    }
    function detectContent(analysis) {
        let berry = detectBerry(analysis)
        let content = berry === "Lingonberries" ? analysis.lingon : analysis.blueberries
        content < 1 ? content = "<1 %" :
        content > 99 ? content = "> 99 %" :
        content = Math.round(content) + ' %'
        return content
    }
    function detectWrong(analysis) {
        let berry = detectBerry(analysis)
        let content = berry === "Lingonberries" ? analysis.blueberries : analysis.lingon
        content < 1 ? content = "<1 %" :
        content > 99 ? content = "> 99 %" :
        content = Math.round(content) + ' %'
        return content
    }
    function roundLogic(type) {
        let content = type === "waste" ? analysis.waste :
        type === "unripe" ? analysis.unripe :
        type === "unidentified" ? analysis.unidentified :
        "ERR: roundLogic at Row.js"
        content < 1 ? content = "<1 %" :
        content > 99 ? content = "> 99 %" :
        content = Math.round(content) + ' %'
        return content
    }
    const styles = StyleSheet.create({
      document: {
        margin: 0,
      },
      page: {
        fontFamily: 'Helvetica',
        fontSize: '14pt',
      },
      cont: {
        margin: '18mm 20mm',
      },
      title: {
        fontSize: '26',
        marginBottom: '15mm',
      },
      title2: {
        fontSize: '14',
        marginBottom: '15mm',
      },
      img: {
        marginTop: 25,
        marginBottom: 15,
        maxHeight: 270,
        objectFit: "contain"
      },
      rect2: {
        backgroundColor: '#efefef',
        paddingTop: 10,
        paddingBottom: 20,
        marginTop: 25,
        marginBottom: 15,
      },
      table: {
        display: 'table',
      },
      tableRow: {
        flexDirection: 'row',
      },
      tableCell: {
        marginTop: 5,
        fontSize: 12,
        padding: 5,
        width: '33.3%',
        borderRight: 10,
        borderLeft: 10,
        borderRightColor: 'white',
        borderLeftColor: 'white',
      },
      tableRect: {
        width: '33.3%',
        borderRight: 10,
        borderLeft: 15,
        borderRightColor: 'white',
        borderLeftColor: 'white',
      },
      rect: {
        height: 1.5,
        backgroundColor: 'black'
      },
      tableTitle: {
        fontSize: 10,
        paddingLeft: 5,
        paddingTop: 4,
        width: '33.3%',
        borderRight: 10,
        borderLeft: 10,
        borderRightColor: 'white',
        borderLeftColor: 'white',
      },
      tableCell2: {
        marginTop: 5,
        fontSize: 12,
        padding: 5,
        width: '33.3%',
        borderRight: 10,
        borderLeft: 10,
        borderRightColor: '#efefef',
        borderLeftColor: '#efefef',
      },
      tableRect2: {
        width: '33.3%',
        borderRight: 10,
        borderLeft: 15,
        borderRightColor: '#efefef',
        borderLeftColor: '#efefef',
      },
      tableTitle2: {
        fontSize: 10,
        paddingLeft: 5,
        paddingTop: 4,
        width: '33.3%',
        borderRight: 10,
        borderLeft: 10,
        borderRightColor: '#efefef',
        borderLeftColor: '#efefef',
      },
      tableTitleComment: {
        fontSize: 10,
        paddingLeft: 5,
        paddingTop: 4,
        width: '100%%',
        borderRight: 10,
        borderLeft: 10,
        borderRightColor: 'white',
        borderLeftColor: 'white',
      },
      tableRectComment: {
        width: '100%',
        borderRight: 10,
        borderLeft: 15,
        borderRightColor: 'white',
        borderLeftColor: 'white',
      },
      tableCellComment: {
        marginTop: 5,
        fontSize: 12,
        padding: 5,
        width: '100%',
        borderRight: 10,
        borderLeft: 10,
        borderRightColor: 'white',
        borderLeftColor: 'white',
      },
      tableTitleName: {
        fontSize: 10,
        paddingLeft: 5,
        paddingTop: 4,
        width: '50%',
        borderRight: 10,
        borderLeft: 10,
        borderRightColor: 'white',
        borderLeftColor: 'white',
      },
      tableRectName: {
        width: '50%',
        borderRight: 10,
        borderLeft: 15,
        borderRightColor: 'white',
        borderLeftColor: 'white',
      },
      tableCellName: {
        marginTop: 5,
        fontSize: 12,
        padding: 5,
        width: '50%',
        borderRight: 10,
        borderLeft: 10,
        borderRightColor: 'white',
        borderLeftColor: 'white',
      },
    });
    return (
      <Document style={styles.document}>
        <Page style={styles.page}>
          <View style={styles.cont}>
            <Text style={styles.title}>{`Quality report - ${analysis.berry === "blueberries"? "blueberries" : "lingonberries"}`}</Text>
            <View style={styles.table}>
              <View style={styles.tableRow}>
                <View style={styles.tableCellName}>
                  <Text>{data.date}</Text>
                </View>
                <View style={styles.tableCellName}>
                  <Text>{data.name}</Text>
                </View>
              </View>
              <View style={styles.tableRow}>
                <View style={styles.tableRectName}>
                  <View style={styles.rect}></View>
                </View>
                <View style={styles.tableRectName}>
                  <View style={styles.rect}></View>
                </View>
              </View>
              <View style={styles.tableRow}>
                <View style={styles.tableTitleName}>
                  <Text>Date</Text>
                </View>
                <View style={styles.tableTitleName}>
                  <Text>Sender</Text>
                </View>
              </View>
            </View>
            <Image style={styles.img} src={images[0]}></Image>
            <View style={styles.table}>
              <View style={styles.tableRow}>
                <View style={styles.tableCell}>
                  <Text>{data.owner}</Text>
                </View>
                <View style={styles.tableCell}>
                  <Text>{data.deliveryNumber}</Text>
                </View>
                <View style={styles.tableCell}>
                  <Text>{data.lotNumber}</Text>
                </View>
              </View>
              <View style={styles.tableRow}>
                <View style={styles.tableRect}>
                  <View style={styles.rect}></View>
                </View>
                <View style={styles.tableRect}>
                  <View style={styles.rect}></View>
                </View>
                <View style={styles.tableRect}>
                  <View style={styles.rect}></View>
                </View>
              </View>
              <View style={styles.tableRow}>
                <View style={styles.tableTitle}>
                  <Text>Owner</Text>
                </View>
                <View style={styles.tableTitle}>
                  <Text>Delivery number</Text>
                </View>
                <View style={styles.tableTitle}>
                  <Text>Lot number</Text>
                </View>
              </View>
            </View>
            <View style={styles.table}>
              <View style={styles.tableRow}>
                <View style={styles.tableCell}>
                  <Text>{data.depot}</Text>
                </View>
                <View style={styles.tableCell}>
                  <Text>{data.stoneGlassMetals}</Text>
                </View>
                <View style={styles.tableCell}>
                  <Text>{data.rottenBerriesMold}</Text>
                </View>
              </View>
              <View style={styles.tableRow}>
                <View style={styles.tableRect}>
                  <View style={styles.rect}></View>
                </View>
                <View style={styles.tableRect}>
                  <View style={styles.rect}></View>
                </View>
                <View style={styles.tableRect}>
                  <View style={styles.rect}></View>
                </View>
              </View>
              <View style={styles.tableRow}>
                <View style={styles.tableTitle}>
                  <Text>Depot</Text>
                </View>
                <View style={styles.tableTitle}>
                  <Text>Stone, glass, metal</Text>
                </View>
                <View style={styles.tableTitle}>
                  <Text>Rotten berries, mold</Text>
                </View>
              </View>
            </View>

            <View style={styles.rect2}>
            <View style={styles.table}>
              <View style={styles.tableRow}>
                <View style={styles.tableCell2}>
                  <Text>Type</Text>
                </View>
                <View style={styles.tableCell2}>
                  <Text>NOS Data</Text>
                </View>
                <View style={styles.tableCell2}>
                  <Text>Manual Data</Text>
                </View>
              </View>
              <View style={styles.tableRow}>
        
                <View style={styles.tableCell2}>
                  <Text>{analysis.berry === 'lingon' ? 'Lingonberries' : 'Blueberries'}</Text>
                </View>
                <View style={styles.tableCell2}>
                  <Text>{detectContent(analysis)}</Text>
                </View>
                <View style={styles.tableCell2}>
                <Text>{analysis.berry === 'lingon' ? data.manualLi + ' %' : data.manualBl + ' %'}</Text>
                </View>
              </View>
              <View style={styles.tableRow}>
                <View style={styles.tableCell2}>
                  <Text>{analysis.berry === 'lingon' ? 'Blueberries' : 'Lingonberries'}</Text>
                </View>
                <View style={styles.tableCell2}>
                  <Text>{detectWrong(analysis)}</Text>
                </View>
                <View style={styles.tableCell2}>
                <Text>{analysis.berry === 'lingon' ? data.manualBl + ' %' : data.manualLi + ' %'}</Text>
                </View>
              </View>
              <View style={styles.tableRow}>
                <View style={styles.tableCell2}>
                  <Text>Waste</Text>
                </View>
                <View style={styles.tableCell2}>
                  <Text>{roundLogic("waste")}</Text>
                </View>
                <View style={styles.tableCell2}>
                  <Text>{data.manualWa + ' %'}</Text>
                </View>
              </View>
              <View style={styles.tableRow}>
                <View style={styles.tableCell2}>
                  <Text>Unripe/Other Berries</Text>
                </View>
                <View style={styles.tableCell2}>
                  <Text>{roundLogic("unripe")}</Text>
                </View>
                <View style={styles.tableCell2}>
                <Text>{data.manualUr + ' %'}</Text>
                </View>
              </View>
            </View>
          </View>


            <View style={styles.table}>
              <View style={styles.tableRow}>
                <View style={styles.tableCellComment}>
                  <Text>{data.otherComments}</Text>
                </View>
              </View>
              <View style={styles.tableRow}>
                <View style={styles.tableRectComment}>
                  <View style={styles.rect}></View>
                </View>
              </View>
              <View style={styles.tableRow}>
                <View style={styles.tableTitleComment}>
                  <Text>Controlled by / Other comments</Text>
                </View>
              </View>
            </View>

          </View>
        </Page>
      </Document>
    );
  };

  const handleExport = async () => {
    const data = reportData;
    const pdfBlob = await pdf(<MyPDF data={data} analysis={analysis} />).toBlob();
    saveAs(pdfBlob, `analysis-${analysis.id}.pdf`);
  };

  function logOut(cookieName) {
    document.cookie = cookieName + "=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;";
    navigate("/login", {state: {path: pathname}});
  }

  const authToken = document.cookie
    .split(';')
    .find(cookie => cookie.trim().startsWith('authToken='));
  let username = jwtDecode(authToken).username;

  function auth(user) {
    let authorized = false;
    if (username === user) authorized = true
    if (username === "admin") authorized = true
    if (username === "testuser3" && user === "TES0")authorized = true
    authorized ? setIsAuth(true) : setIsAuth(false);
  }

  function convertDate(date) {
    const clientDate = new Date();
    const options = { timeZone: 'UTC' };
    const utcDateString = clientDate.toLocaleString('en-US', options);
    const utcDate = new Date(utcDateString);
    
    const timeDifferenceInMinutes = (clientDate.getTime() - utcDate.getTime()) / 60000;
    const timeDifferenceInMilliseconds = timeDifferenceInMinutes * 60 * 1000;
    
    // Extract the date and time components from the date string
    const [datePart, timePart] = date.split(' ');
    const [year, month, day] = datePart.split('-');
    const [hours, minutes] = timePart.split(':');
    
    // Create a new date object with the extracted components
    const convertedDate = new Date(year, month - 1, day, hours, minutes);
    
    // Add the time difference in milliseconds to the converted date
    const finalDate = new Date(convertedDate.getTime() + timeDifferenceInMilliseconds);
    
    // Extract the adjusted components from the final date
    const adjustedYear = finalDate.getFullYear();
    const adjustedMonth = finalDate.getMonth() + 1;
    const adjustedDay = finalDate.getDate();
    const adjustedHours = finalDate.getHours();
    const adjustedMinutes = finalDate.getMinutes();
    const adjustedSeconds = finalDate.getSeconds();
    const adjustedMilliseconds = finalDate.getMilliseconds();
    
    // Format the adjusted components into a new date string
    const adjustedDateString = `${adjustedYear}-${adjustedMonth.toString().padStart(2, '0')}-${adjustedDay.toString().padStart(2, '0')} ${adjustedHours.toString().padStart(2, '0')}:${adjustedMinutes.toString().padStart(2, '0')}:${adjustedSeconds.toString().padStart(2, '0')}.${adjustedMilliseconds.toString().padStart(3, '0')}`;
    return adjustedDateString.substring(0,10)
  }

  if (isError) {
    return (
      <>
        <div className="head">
          <button style={{visibility: "hidden"}}>Reset filters</button>
          <Link to="/"><img src={logo} alt="NOS Logo" className="logo" /></Link>
          <button style={{visibility: "hidden"}}>Upload Photo</button>
        </div>
        <div className="loading-container">
          <p>Something went wrong, please try again or contact</p>
          <a href="mailto:support@nostechnology.com">support@nostechnology.com</a>
        </div>
      </>
    )
  }

  if (isLoading) {
    return (
      <>
        <div className="head">
          <button style={{visibility: "hidden"}}>Reset filters</button>
          <Link to="/"><img src={logo} alt="NOS Logo" className="logo" /></Link>
          <button style={{visibility: "hidden"}}>Upload Photo</button>
        </div>
        <div className="loading-container">
          <img src={loading} alt="Loading" className="loading-spinner" />
        </div>
      </>
    )
  } else if (isAuth && isClicked) {
    return (
      <>
        <div className="fullscreen" style={isRotated ? {transform: "translateY(-5vh)"} : null}>
          <div className="fullscreen-images">
            <img style={{ visibility: currentImageIndex === 0 ? 'hidden' : 'visible' }} className="fullscreen-imageBtn" src={left} alt="prev" onClick={() => setImageIndex(currentImageIndex-1)}></img>
            <div id="fullscreen-photo" ref={fullscreenPhotoRef}>
              <img className={isRotated ? "rotate" : "no-rotate"} src={images[currentImageIndex]} alt="" onClick={() => {setIsClicked(false)}}></img>
            </div>
            <img style={{ visibility: currentImageIndex === 4 ? 'hidden' : 'visible' }} className="fullscreen-imageBtn" src={right} alt="next" onClick={() => setImageIndex(currentImageIndex+1)}></img>
          </div>
          <table cellPadding={'0'} cellSpacing={'0'} id="fullscreen-table">
            <thead>
                <tr>
                    <td style={currentImageIndex === 1? {color: "#ff9e29"} : {color: "#1a1a1a"}}>{analysis.berry === 'lingon' ? 'Lingonberries' : 'Blueberries'}</td>
                    <td style={currentImageIndex === 2? {color: "#ff9e29"} : {color: "#1a1a1a"}}>{analysis.berry === 'lingon' ? 'Blueberries' : 'Lingonberries'}</td>
                    <td style={currentImageIndex === 3? {color: "#ff9e29"} : {color: "#1a1a1a"}}>{'Waste'}</td>
                    <td style={currentImageIndex === 4? {color: "#ff9e29"} : {color: "#1a1a1a"}}>{'Unripe/'}<br/>{'Other Berries'}</td>
                </tr>
            </thead>
            <tbody><Row individual={"2"} currentImageIndex={currentImageIndex} report={analysis} download={false} /></tbody>
          </table>
        </div>
        <div className="extra"></div>
      </>
    )
  } else if (isAuth) {
    return (
      <>
        <div className="head">
          <Link to={"/"}>
            <button style={{color: "#FF9E29"}}>Back</button>
          </Link>
          <Link to="/"><img src={logo} alt="NOS Logo" className="logo" /></Link>
          <Upload />
        </div>
        <div className='card' style={{width: '100%', overflowX: 'auto'}}>
            <div className='header'>
                <h1>Analysis</h1>
                <div>id: {reportId}</div>
            </div>
            <div style={{display: "flex", flexDirection: "column", alignItems: "center"}}>
              <div className="images" id="images">
                <img style={{ visibility: currentImageIndex === 0 ? 'hidden' : 'visible' }} className="imageBtn" src={left} alt="prev" onClick={() => setImageIndex(currentImageIndex-1)}></img>
                <img id="photo" src={images[currentImageIndex]} alt="" onClick={() => {
                  setIsClicked(true)
                  setTimeout(() => {
                    handleResize(); // Set the table width after a short delay
                  }, 10);
                  }}></img>
                <img style={{ visibility: currentImageIndex === 4 ? 'hidden' : 'visible' }} className="imageBtn" src={right} alt="next" onClick={() => setImageIndex(currentImageIndex+1)}></img>
              </div>
              <table cellPadding={'0'} cellSpacing={'0'} id="table1">
                  <thead>
                      <tr>
                          <td style={currentImageIndex === 1? {color: "#ff9e29"} : {color: "#1a1a1a"}}>{analysis.berry === 'lingon' ? 'Lingonberries' : 'Blueberries'}</td>
                          <td style={currentImageIndex === 2? {color: "#ff9e29"} : {color: "#1a1a1a"}}>{analysis.berry === 'lingon' ? 'Blueberries' : 'Lingonberries'}</td>
                          <td style={currentImageIndex === 3? {color: "#ff9e29"} : {color: "#1a1a1a"}}>{'Waste'}</td>
                          <td style={currentImageIndex === 4? {color: "#ff9e29"} : {color: "#1a1a1a"}}>Unripe/<br/>Other Berries</td>
                      </tr>
                  </thead>
                  <tbody><Row individual={"2"} currentImageIndex={currentImageIndex} report={analysis} download={false} /></tbody>
                  <thead>
                      <tr>
                          <td id="variety-header">{'Variety'}</td>
                          <td id="date-header">{'Date'}</td>
                          <td>{'Type'}</td>
                          <td>{'Upload'}</td>
                      </tr>
                  </thead>
                  <tbody><Row individual={"1"} currentImageIndex={currentImageIndex} report={analysis} download={false} /></tbody>
                  {username === "admin" && <thead>
                      <tr>
                        <td>{'Unidentified'}</td>
                        <td>{'Customer'}</td>
                      </tr>
                  </thead>}
                  {username === "admin" && <tbody><Row individual={"3"} currentImageIndex={currentImageIndex} report={analysis} download={false} /></tbody>}
              </table>
              <p style={{marginTop: "10px"}}>(weight %)</p>
            </div>
        </div>
        <div className="card">
            <div className='header'>
                <h1>Report details (optional)</h1>
                <div className="tooltip-container">
                    <img src={download} alt="download" onClick={handleExport} className="icon"/>
                    <span className="tooltip">Download</span>
                </div>
            </div>

            <button onClick={getPreviousReportData}>
              Copy from previous
            </button>

            <div className="two">
              <input type="text" name="pdf" placeholder="Date..." defaultValue={convertDate(analysis.date)} onChange={(e) => setReportData({ ...reportData,date: e.target.value})}></input>
              <input type="text" name="pdf" placeholder='Sender...' defaultValue={reportData.name ? reportData.name : ''} onChange={(e) => setReportData({ ...reportData,name: e.target.value})}></input>
              <input type="text" name="pdf" placeholder="Owner..." defaultValue={reportData.owner ? reportData.owner : ''} onChange={(e) => setReportData({ ...reportData,owner: e.target.value})}></input>
            </div>
            <div className="three">
              <input type="text" name="pdf" placeholder="Depot..." defaultValue={reportData.depot ? reportData.depot : ''} onChange={(e) => setReportData({ ...reportData,depot: e.target.value})}></input>
              <input type="text" name="pdf" placeholder="Delivery number..." defaultValue={reportData.deliveryNumber ? reportData.deliveryNumber : ''} onChange={(e) => setReportData({ ...reportData,deliveryNumber: e.target.value})}></input>
              <input type="text" name="pdf" placeholder="Pallet ID..." defaultValue={reportData.lotNumber ? reportData.lotNumber : ''} onChange={(e) => setReportData({ ...reportData,lotNumber: e.target.value})}></input>
            </div>
            
            <div className="three">
              <input type="number" name="pdf" placeholder="% Blueberries..." step="1" defaultValue={reportData.manualBl ? reportData.manualBl : ''} onChange={(e) => setReportData({ ...reportData, manualBl: e.target.value })}></input>
              <input type="number" name="pdf" placeholder="% Lingonberries..." step="1" defaultValue={reportData.manualLi ? reportData.manualLi : ''} onChange={(e) => setReportData({ ...reportData, manualLi: e.target.value })}></input>
              <input type="number" name="pdf" placeholder="% Waste..." step="1" defaultValue={reportData.manualWa ? reportData.manualWa : ''} onChange={(e) => setReportData({ ...reportData, manualWa: e.target.value })}></input>
              <input type="number" name="pdf" placeholder="% Unripe & other berries..." step="1" defaultValue={reportData.manualUr ? reportData.manualUr : ''} onChange={(e) => setReportData({ ...reportData, manualUr: e.target.value })}></input>
            </div>
            <div className="three">
              <label>Stone, glass, metals.</label>
              <select
                value={reportData.stoneGlassMetals}
                onChange={(e) => setReportData({ ...reportData, stoneGlassMetals: e.target.value })}
              >
                <option value="">Select an option</option>
                <option value="Yes">Yes</option>
                <option value="No">No</option>
              </select>
            </div>
            <div className="three">
              <label>Rotten berries, mold.</label>
              <select
                value={reportData.rottenBerriesMold}
                onChange={(e) => setReportData({ ...reportData, rottenBerriesMold: e.target.value })}
              >
                <option value="">Select an option</option>
                <option value="Yes">Yes</option>
                <option value="No">No</option>
              </select>
            </div>

            <div className="one">
              <input type="text" name="pdf" placeholder="Controlled by / other comments..." defaultValue={reportData.otherComments ? reportData.otherComments : ''} onChange={(e) => setReportData({ ...reportData,otherComments: e.target.value})}></input>
            </div>
            <button style={{ backgroundColor: isSaved ? 'green' : '#FFC88D' }} onClick={handleSave}>
              {isSaved ? 'Saved!' : 'Save manual entries'}
            </button>
            {/* <div className="one">
              <input type="text" name="pdf" placeholder="Controlled by..." onChange={(e) => setReportData({ ...reportData,controlledBy: e.target.value})}></input>
            </div> */}
        </div>
        <Footer/>
      </>
    )
  } else if (!isAuth) {
    return (
      <>
        <div className="head">
          <button style={{visibility: "hidden"}}>Reset filters</button>
          <Link to="/"><img src={logo} alt="NOS Logo" className="logo" /></Link>
          <button style={{visibility: "hidden"}}>Upload Photo</button>
        </div>
        <div className="login">
          <div className="card" style={{maxWidth: "300px", maxHeight: "80px"}}>
            <div className="header" style={{marginBottom: "15px"}}>
              <h1>Not authorized</h1>
            </div>
            <div className="authcard">
              <Link to={"/"}>
                <button style={{backgroundColor: "#FFC88D"}}>Home Page</button>
              </Link>
              <button className="loginBtn" onClick={(e) => logOut("authToken")}>Log In</button>
            </div>
          </div>
        </div>
      </>
    )
  }
}

export default Report;
