import React, { useState, useRef } from 'react';
import { useNavigate } from 'react-router-dom';
import jwtDecode from 'jwt-decode';

const Upload = () => {
  const [showDropdown, setShowDropdown] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [isError, setIsError] = useState(false);
  const fileInputRef = useRef();
  const navigate = useNavigate();

  const authToken = document.cookie
    .split(';')
    .find(cookie => cookie.trim().startsWith('authToken='));
  let customerId = jwtDecode(authToken).username;
  if (customerId === "testuser3" || customerId === "admin") customerId = "TES0"
  
  async function getUserLocation() {
    return new Promise((resolve, reject) => {
      let location = 'N/A';
  
      if ("geolocation" in navigator) {
        try {
          navigator.geolocation.getCurrentPosition(
            (position) => {
              const latitude = position.coords.latitude;
              const longitude = position.coords.longitude;
              location = `lat: ${latitude}, lon: ${longitude}`;
              resolve(location);
            },
            (error) => {
              // If permission is denied or location is not available, resolve with "N/A"
              resolve(location); // Resolve with "N/A"
            }
          );
        } catch (error) {
          console.log("Geolocation error:", error);
          reject(error);
          resolve(location); // Resolve with "N/A"
        }
      } else {
        setIsError(true);
        const error = new Error("Geolocation is not supported");
        console.log(error);
        reject(error);
        resolve(location); // Resolve with "N/A"
      }
    });
  }

  const toggleDropdown = () => {
    setShowDropdown((prevState) => !prevState);
  };

  const toggleLoading = () => {
    setIsLoading((prevState) => !prevState);
  };

  const handleClick = () => {
    toggleDropdown();
  };

  const handleFileChange = (event, intExt) => {
    const file = event.target.files[0];

    if (showDropdown) {
      uploadPhoto(file, intExt);
    } else {
      uploadPhoto(file, "Internal");
    }
  };

  const uploadPhoto = async (file, intExt) => {
    const cloudName = process.env.REACT_APP_CLOUDINARY_CLOUD_NAME;
    const uploadPreset = process.env.REACT_APP_CLOUDINARY_UPLOAD_PRESET;

    const formData = new FormData();
    formData.append('file', file);
    formData.append('upload_preset', uploadPreset);
    const cloudinaryURL = `https://api.cloudinary.com/v1_1/${cloudName}/image/upload`;

    try {
      toggleLoading();
      const response = await fetch(cloudinaryURL, {
        method: 'POST',
        body: formData,
      });

      if (response.ok) {
        const { secure_url } = await response.json();
        const apiUrl = 'https://nosapi.herokuapp.com/v2/upload/';

        let location = await getUserLocation();

        await fetch(apiUrl, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
            'x-api-key': process.env.REACT_APP_API_KEY
          },
          body: JSON.stringify({
            imgUrl: secure_url,
            intExt: intExt,
            customerId: customerId,
            nosProductInfo: `{'web': {'version': 'v0.1', 'snOrUser': '${customerId}', 'location': '${location}', 'metaData': 'N/A'}}`
          }),
        })
        .then(response => response.json())
        .then(data => {
          toggleDropdown();
          toggleLoading();
          navigate(`/id/${data.id}`);
        })
      }
    } catch (error) {
      setIsError(true)
      console.error('Error:', error);
    }
  };

  if (isError) alert("something went wrong, please try again or contact support@nostechnology.com")

  return (
    <div>
      {!isLoading && showDropdown ? (
        <div className='dropdown'>
          <label id='leftDrop' className='dropBtn' htmlFor="file-input-internal">
            Internal
            <input
              id="file-input-internal"
              type="file"
              accept="image/*"
              style={{ display: 'none' }}
              onChange={(e) => handleFileChange(e, "Internal")}
              ref={fileInputRef}
            />
          </label>
          <label id='rightDrop' className='dropBtn' htmlFor="file-input-external">
            External
            <input
              id="file-input-external"
              type="file"
              accept="image/*"
              style={{ display: 'none' }}
              onChange={(e) => handleFileChange(e, "External")}
              ref={fileInputRef}
            />
          </label>
        </div>
      ) : !isLoading ? (
        <button style={{ backgroundColor: '#FFC88D' }} onClick={handleClick}>
          Upload Photo
        </button>
      ) : (
        <button style={{ backgroundColor: '#FFFBF7' }}>
          Uploading...
        </button>
      )}
    </div>
  );
};

export default Upload;
