
// eslint-disable-next-line
import {
  Chart as ChartJS,
  LineElement,
  TimeScale,
  LinearScale,
  PointElement,
  Tooltip,
  Legend
} from 'chart.js/auto'
import 'chartjs-adapter-date-fns'
import { Line } from 'react-chartjs-2'
import React from 'react';

ChartJS.register(
  LineElement,
  TimeScale,
  LinearScale,
  PointElement,
  Tooltip,
  Legend
 )

export default function LineChart({chartData, scale}) {
  return (
    <Line data={chartData} options={{
      maintainAspectRatio: false,
      scales: {
        x: {
          type: 'time',
          time: {
            unit: scale
          }
        },
        y: {
          max: 100
        }
      }
    }} />
  )
}
