// DataContext.js
import React, { createContext, useContext, useState } from 'react';

const DataContext = createContext();

export const useDataContext = () => useContext(DataContext);

export function DataProvider({ children }) {
  const [globalFilteredData, setGlobalFilteredData] = useState(null);

  const today = new Date()
  const year = today.getFullYear()
  let month = today.getMonth().toString()
  let prevMonth
  let prevYear
  if (today.getMonth() === 0) {
    prevMonth = 12
    prevYear = year - 1;
  } else {
    prevMonth = today.getMonth()
    prevYear = year
  }
  if (prevMonth.toString().length === 1) prevMonth = '0' + prevMonth.toString();
  if (month.toString().length === 1) month = '0' + month.toString();
  (today.getMonth()+1).toString().length === 2 ?
    month = (today.getMonth()+1) :
    month = '0' + (today.getMonth()+1).toString()
  let date
  (today.getDate()).toString().length === 2 ?
    date = (today.getDate()) :
    date = '0' + (today.getDate())
  let pDate
  today.getDate() === 31 ? pDate = 30 : pDate = date
  const currentDate = year + '-' + month + '-' + date
  const prevDate = prevYear + '-' + prevMonth + '-' + pDate

  const [activeFilters, setActiveFilters] = useState({
    date: [prevDate, currentDate],
    variety: 'lingon',
    type: 'both',
    upload: 'both'
  });

  return (
    <DataContext.Provider value={{ globalFilteredData, setGlobalFilteredData, activeFilters, setActiveFilters }}>
      {children}
    </DataContext.Provider>
  );
}
