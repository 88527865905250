
import React from "react"
import { useDataContext } from '../components/DataContext';

export default function useVariety() {
  const { activeFilters, setActiveFilters } = useDataContext()

  return {
    renderVariety:(
    <form className="filters">
      <input
        type="radio"
        name="variety"
        id="lingon"
        value="lingon"
        onChange={(e) => {
          setActiveFilters({...activeFilters, variety: e.target.value})
        }}
        defaultChecked={activeFilters.variety === "lingon"}
      />
      <label htmlFor="lingon">
        Lingonberries
      </label>
      <input
        type="radio"
        name="variety"
        id="blueberries"
        value="blueberries"
        onChange={(e) => {
          setActiveFilters({...activeFilters, variety: e.target.value})
        }}
        defaultChecked={activeFilters.variety === "blueberries"}
      />
      <label htmlFor="blueberries">
        Blueberries
      </label>
    </form>
  )}
}
  