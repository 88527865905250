import React from 'react';

export default function Filter({
    active,
    filter
}) {
    return (
    <div style={active ? {display: "block"} : {display: "none"}} className='filter-type'>
        {filter}
    </div>
  )
}
