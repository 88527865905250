
import download from "../images/download.svg"
import Row from "./Row"
import { utils, write } from 'xlsx';
import React from 'react';
import { saveAs } from "file-saver";
import jwtDecode from "jwt-decode";

export default function Reports({
    senderFilter,
    berryFilter,
    startDate,
    endDate,
    filteredReports
}) {
    const authToken = document.cookie
        .split(';')
        .find(cookie => cookie.trim().startsWith('authToken='));
    let username = jwtDecode(authToken).username;
    let admin = username === "admin" ? true : false;

    function handleExport() {
        const wb = utils.book_new();
        const table = document.getElementById('exporter');
        const sheet = utils.table_to_sheet(table);
        utils.book_append_sheet(wb, sheet, 'Sheet1');
        const wbout = write(wb, { bookType: 'xlsx', type: 'array' });

        const file = new Blob([wbout], { type: 'application/octet-stream' });
        saveAs(file, `${berryFilter}-${startDate}-${endDate}-${senderFilter}.xlsx`);
    }
    
    if (filteredReports.length === 0) {
        return (
            <>
            <div className='card' style={{
                    width: '100%',
                    height: '200px',
                    overflowX: 'auto'
                }}>
                <div className='header'>
                    <h1>Analyses</h1>
                </div>
                <div className="no-wrapper">No analysis passed the filters</div>
            </div>
            <div style={{overflowX: "auto", visibility: 'hidden', display: 'none'}}>
                <table cellPadding={'0'} cellSpacing={'0'} id="exporter">
                    <thead>
                        <tr>
                            <td>{'Date'}</td>
                            <td>{'Sender'}</td>
                            <td>{'Berry'}</td>
                            <td>{berryFilter === 'lingon' ? 'Lingonberries' : 'Blueberries'}</td>
                            <td>{berryFilter === 'lingon' ? 'Blueberries' : 'Lingonberries'}</td>
                            <td>{'Waste'}</td>
                            <td>{'Unripe/Other Berries'}</td>
                            <td>{'Upload'}</td>
                            <td>{'Id'}</td>
                        </tr>
                    </thead>
                    <tbody>
                        {filteredReports.map((report, index) => {
                            return <Row key={index} report={report} download={true} />
                        })}
                    </tbody>
                </table>
            </div>
            </>
        )
    }
    return (
        <>
        <div className='card' style={{
                width: '100%',
                height: '200px',
                overflowX: 'auto',
                overflowY: 'auto'
            }}>
            <div className="header-container">
                <div className='header'>
                    <h1>Analyses</h1>
                    <p style={{transform: "translateX(-70%)"}}>(weight %)</p>
                    <div className="tooltip-container">
                        <img src={download} alt="download" onClick={handleExport} className="icon"/>
                        <span className="tooltip">Download</span>
                    </div>
                </div>
            </div>
            <table cellPadding={'0'} cellSpacing={'0'} id="table">
                <thead>
                    <tr>
                        <td id="date-header">{'Date'}</td>
                        <td>{'Type'}</td>
                        <td id="variety-header">{'Variety'}</td>
                        <td>{berryFilter === 'lingon' ? 'Lingonberries' : 'Blueberries'}</td>
                        <td>{berryFilter === 'lingon' ? 'Blueberries' : 'Lingonberries'}</td>
                        <td>{'Waste'}</td>
                        <td>{'Unripe/Other Berries'}</td>
                        <td>{'Upload'}</td>
                        {admin && <td>{'Unidentified'}</td>}
                        {admin && <td>{'Customer'}</td>}
                        <td>{'Id'}</td>
                    </tr>
                </thead>
                <tbody>
                    {filteredReports.map((report, index) => {
                        return <Row key={index} individual={false} report={report} download={false} />
                    })}
                </tbody>
            </table>
        </div>
        <div style={{overflowX: "auto", visibility: 'hidden', display: 'none'}}>
            <table cellPadding={'0'} cellSpacing={'0'} id="exporter">
                <thead>
                    <tr>
                        <td>{'Date'}</td>
                        <td>{'Sender'}</td>
                        <td>{'Berry'}</td>
                        <td>{berryFilter === 'lingon' ? 'Lingonberries' : 'Blueberries'}</td>
                        <td>{berryFilter === 'lingon' ? 'Blueberries' : 'Lingonberries'}</td>
                        <td>{'Waste'}</td>
                        <td>{'Unripe/Other Berries'}</td>
                        <td>{'Upload'}</td>
                        {admin && <td>{'Unidentified'}</td>}
                        {admin && <td>{'Customer'}</td>}
                        <td>{'Id'}</td>
                    </tr>
                </thead>
                <tbody>
                    {filteredReports.map((report, index) => {
                        return <Row key={index} report={report} download={true} />
                    })}
                </tbody>
            </table>
        </div>
        </>
    )
}
