import React, { useState, useEffect } from "react";
import logo from "../images/NOS-Logo-Horizontal-Color-Black.svg";
import loading from "../images/Rolling-1s-200px.svg";
import right from "../images/right.svg";
import useSender from "./Sender";
import useVariety from "./Variety";
import useUploadType from "./UploadType";
import useDate from "./Date";
import Filter from "./Filter";
import Reports from "./Reports";
import Result from "./Result";
import Upload from "./Upload";
import Footer from "./Footer";
import { Link, useOutletContext } from "react-router-dom";
import { useDataContext } from './DataContext';

export default function Home() {
  const [isLoading, setIsLoading] = useState(true);
  const [filterType, setFilterType] = useState(null);
  const [filterText, setFilterText] = useState(`blueberries between\nYYYY-MM-DD and YYYY-MM-DD`);
  const [data, setData] = useState([]);
  const [reports, setReports] = useState([]);
  const [both, setBoth] = useState([]);
  const [internal, setInternal] = useState([]);
  const [external, setExternal] = useState([]);
  const [machine, setMachine] = useState([]);
  const [web, setWeb] = useState([]);
  const [isError, setIsError] = useState(false);
  const [compare, setCompare] = useState({
    compare: false,
    type: "",
    double: false,
  });
  
  const { setGlobalFilteredData, activeFilters, setActiveFilters } = useDataContext()
  const { renderUploadType } = useUploadType();
  const { renderSender } = useSender();
  const { renderDate } = useDate();
  let { renderVariety } = useVariety();
  const customerId = useOutletContext();

  let variety = activeFilters.variety;
  let sender = activeFilters.type;
  let uploadType = activeFilters.upload;
  let startDate = activeFilters.date[0];
  let endDate = activeFilters.date[1];

  const url1 = "https://nosapi.herokuapp.com/v1/download/";
  const apiUrl = (customer) => {
    let url2
    customer === "admin" ? url2 = ``:
    customer === "testuser3" ? url2 = `?customerId=TES0`:
    url2 = `?customerId=${customer}`;
    return url1+url2;
  }

  useEffect(() => {
    const fetchData = async () => {
      try {
        const apiKey = process.env.REACT_APP_API_KEY;
        const response = await fetch(apiUrl(customerId), {
          method: "GET",
          headers: {
            "x-api-key": apiKey,
          },
        });
        if (!response.ok) {
          setIsError(true);
          throw new Error("Network response was not ok");
        }
        const data = await response.json();
        setReports(data);
        setActiveFilters({...activeFilters, variety: data[0].berry})
        filter(data);
        setIsLoading(false);
      } catch (error) {
        console.error("There was a problem with the fetch operation:", error);
        setIsError(true);
      }
    };

    fetchData();

    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    filter(reports);
    // eslint-disable-next-line
  }, [startDate, endDate, variety, sender, uploadType]);

  useEffect(() => {
    if (uploadType === "compare" && sender === "compare") {
      setCompare((prevState) => ({
        ...prevState,
        double: true,
      }));
    } else if (uploadType === "compare" || sender === "compare") {
      const type = uploadType === "compare" ? "upload" : "sender";
      setCompare({
        compare: true,
        type,
        double: false,
      });
    } else {
      setCompare((prevState) => ({
        ...prevState,
        compare: false,
      }));
    }
    // eslint-disable-next-line
  }, [uploadType, sender]);

  function detectUpload(report) {
      let upload
      if (report.nosProductInfo === "{testblob}" ||
      report.nosProductInfo === "“{“Test”: {“Version”: “V1”, “ID”: “Test”, ”Location”: “IP-address”}}”" ||
      report.nosProductInfo === "{“Test”: {“Version”: “V1”, “ID”: “Test”, ”Location”: “IP-address”}}" ||
      report.nosProductInfo === "{BLOBHERE}" ||
      report.nosProductInfo === "{'Machine': {'Version': “V1”, 'SNorUser': '57b37cad', 'Location': '192.168.0.1', 'metaData': 'anyDataHere'}}") {
          upload = "N/A"
          return upload
      }
      try {
        let json = JSON.parse(report.nosProductInfo.replace(/'/g, '"'))
        if (json.web) {
            upload = "Web"
            return upload
        }
        if (json.machine || json.Machine) {
            upload = "Machine"
            return upload
        }
      } catch (error) {
        return "N/A"
      }
  }

  function filter(reports) {
    const filteredReports = reports.filter((report) => {
      const filter1 =
        report.internalExternal.toLowerCase().includes(sender) ||
        sender === "both" ||
        sender === "compare";
      const filter2 = report.berry.toLowerCase().includes(variety);
      const filter3 =
        new Date(report.date).getTime() >=
          new Date(startDate + "T00:00").getTime() &&
        new Date(report.date).getTime() <=
          new Date(endDate + "T23:59").getTime();
      const filter4 =
        detectUpload(report) === uploadType ||
        uploadType === 'both' ||
        uploadType === 'compare';

      setFilterText(`${sender !== "both" && sender !== "compare" ? sender : ""} ${variety === "blueberries" ? variety : "lingonberries"} ${uploadType !== "both" && uploadType !== "compare" ? `uploaded from ${uploadType.toLowerCase()}` : ""} between ${startDate} and ${endDate}`);
      return filter1 && filter2 && filter3 && filter4;
    });

    const internalArr = [];
    const externalArr = [];
    const machineArr = [];
    const webArr = [];
    const bothArr = [];

    filteredReports.forEach((report) => {
      const berry = report.berry;
      const type = report.internalExternal;
      const webMachine = detectUpload(report);
      const date = report.date;
      let content = berry === "lingon" ? report.lingon : report.blueberries;

      if (sender === "compare") {
        type === "Internal"
          ? internalArr.push({ x: date, y: content })
          : externalArr.push({ x: date, y: content });
      }

      if (uploadType === "compare") {
        webMachine === "Web"
          ? webArr.push({ x: date, y: content })
          : machineArr.push({ x: date, y: content });
      }

      bothArr.push({ x: date, y: content });
    });

    setData(filteredReports);
    setBoth(bothArr);
    setInternal(internalArr);
    setExternal(externalArr);
    setMachine(machineArr);
    setWeb(webArr);
    setGlobalFilteredData(filteredReports);
  }


  function handleFilterType(filter) {
    filterType === filter ? setFilterType(null) : setFilterType(filter);
  }

  function handleReset() {
    window.location.reload();
  }

  if (isError) {
    return (
      <>
        <div className="head">
          <button style={{visibility: "hidden"}}>Reset filters</button>
          <Link to="/"><img src={logo} alt="NOS Logo" className="logo" /></Link>
          <button style={{visibility: "hidden"}}>Upload Photo</button>
        </div>
        <div className="loading-container">
          <p>Something went wrong, please try again or contact</p>
          <a href="mailto:support@nostechnology.com">support@nostechnology.com</a>
        </div>
      </>
    )
  }

  if (isLoading) {
    return (
      <>
        <div className="head">
          <button style={{visibility: "hidden"}}>Reset filters</button>
          <Link to="/"><img src={logo} alt="NOS Logo" className="logo" /></Link>
          <button style={{visibility: "hidden"}}>Upload Photo</button>
        </div>
        <div className="loading-container">
          <img src={loading} alt="Loading" className="loading-spinner" />
        </div>
      </>
    )
  }

  return (
    <>
      <div className="head">
        <button style={{color: "#EF3030"}} onClick={() => handleReset()}>Reset filters</button>
        <Link to="/"><img src={logo} alt="NOS Logo" className="logo" /></Link>
        <Upload />
      </div>
      <Result
        both={both}
        internal={internal}
        external={external}
        berry={variety}
        web={web}
        machine={machine}
        compare={compare}
        filterText={filterText}
      />
      <div className="card" style={{width: "100%"}}>
        <div className="header">
          <h1>Filters</h1>
          {compare.double && <p id="invalido">cannot compare multiple parameters</p>}
        </div>
          <div id="filtercard">
            <div
              className="filter-name"
              onClick={() => handleFilterType("date")}
              style={filterType === "date"? {color: "#ff9e29"} : {color: "#1a1a1a"}}
            >
              Date
              <img
                src={right}
                alt=""
                style={{
                  transform: filterType === "date"? "rotate(90deg)" : "rotate(0deg)",
                  width: "8px",
                  marginLeft: "10px"
                }}
              />
            </div>
            <div
              className="filter-name"
              onClick={() => handleFilterType("variety")}
              style={filterType === "variety"? {color: "#ff9e29"} : {color: "#1a1a1a"}}
            >
              Variety
              <img
                src={right}
                alt=""
                style={{
                  transform: filterType === "variety"? "rotate(90deg)" : "rotate(0deg)",
                  width: "8px",
                  marginLeft: "10px"
                }}
              />
            </div>
            <div
              className="filter-name"
              onClick={() => handleFilterType("type")}
              style={filterType === "type"? {color: "#ff9e29"} : {color: "#1a1a1a"}}
            >
              Type
              <img
                src={right}
                alt=""
                style={{
                  transform: filterType === "type"? "rotate(90deg)" : "rotate(0deg)",
                  width: "8px",
                  marginLeft: "10px"
                }}
              />
            </div>
            <div
              className="filter-name"
              onClick={() => handleFilterType("upload")}
              style={filterType === "upload"? {color: "#ff9e29"} : {color: "#1a1a1a"}}
            >
              Upload
              <img
                src={right}
                alt=""
                style={{
                  transform: filterType === "upload"? "rotate(90deg)" : "rotate(0deg)",
                  width: "8px",
                  marginLeft: "10px"
                }}
              />
            </div>
          </div>
        <Filter active={ filterType === "date" ? true : false} filter={renderDate} />
        <Filter active={ filterType === "variety" ? true : false} filter={renderVariety} />
        <Filter active={ filterType === "type" ? true : false} filter={renderSender} />
        <Filter active={ filterType === "upload" ? true : false} filter={renderUploadType} />
      </div>
      <Reports
        senderFilter={sender}
        berryFilter={variety}
        startDate={startDate}
        endDate={endDate}
        filteredReports={data}
      />
      <Footer/>
    </>
  );
}
