
import React from 'react';
import { useDataContext } from '../components/DataContext';

export default function useSender() {
  const { activeFilters, setActiveFilters } = useDataContext()

  return {
    renderSender:(
    <form className="filters">
    <input
      type="radio"
      name="sender"
      id="internal"
      value="internal"
      onChange={(e) => {
        setActiveFilters({...activeFilters, type: e.target.value})
      }}
      defaultChecked={activeFilters.type === "internal"}
    />
    <label id="quad" style={{
      marginBottom: '5px'
    }} htmlFor="internal">
      Internal
    </label>
    <input
      type="radio"
      name="sender"
      id="external"
      value="external"
      onChange={(e) => {
        setActiveFilters({...activeFilters, type: e.target.value})
      }}
      defaultChecked={activeFilters.type === "external"}
    />
    <label id="quad" htmlFor="external">
      External
    </label>
    <input
      type="radio"
      name="sender"
      id="both"
      value="both"
      onChange={(e) => {
        setActiveFilters({...activeFilters, type: e.target.value})
      }}
      defaultChecked={activeFilters.type === "both"}
    />
    <label id="quad" htmlFor="both">
      Both
    </label>
    <input
      type="radio"
      name="sender"
      id="compare"
      value="compare"
      onChange={(e) => {
        setActiveFilters({...activeFilters, type: e.target.value})
      }}
      defaultChecked={activeFilters.type === "compare"}
    />
    <label id="quad" htmlFor="compare">
      Compare
    </label>
    </form>
  )}
}
