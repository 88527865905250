import React from 'react';
import { Navigate, Outlet } from 'react-router-dom';
import jwtDecode from 'jwt-decode';

const ProtectedRoute = () => {
  const authToken = document.cookie
    .split(';')
    .find(cookie => cookie.trim().startsWith('authToken='));
  const auth = authToken !== undefined;
  const path = window.location.pathname;

  let decodedToken;
  if(auth) {
    decodedToken = jwtDecode(authToken);
  } else {
    decodedToken = null;
  }
  const customerId = decodedToken !== null ? decodedToken.username : null;

  return auth ? <Outlet context={customerId}/> : <Navigate to="/login" state={{path: path}} />;
};

export default ProtectedRoute;