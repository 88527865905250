
import logo from "../images/NOS-Logo-Horizontal-Color-Black.svg"
import loading from "../images/Rolling-1s-200px.svg";
import Upload from "./Upload";
import Footer from "./Footer";
import { Link, useNavigate} from 'react-router-dom';
import React, { useState, useEffect, useRef } from "react";
import jwtDecode from "jwt-decode";


function Stats () {
  
  const { pathname } = window.location;
  
  const navigate = useNavigate();
  const apiKey = process.env.REACT_APP_API_KEY  
  const [ isLoading, setIsLoading ] = useState(true)
  const [ isError, setIsError ] = useState(false);
  const [ isAuth, setIsAuth ] = useState(false);
  const [ isClicked, setIsClicked ] = useState(false);
  const [jsonData, setJsonData] = useState(null);

  function logOut(cookieName) {
    document.cookie = cookieName + "=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;";
    navigate("/login", {state: {path: pathname}});
  }

  const authToken = document.cookie
    .split(';')
    .find(cookie => cookie.trim().startsWith('authToken='));
  let username = jwtDecode(authToken).username;
  let customerId = jwtDecode(authToken).username;
  if (customerId === "testuser3" || customerId === "admin") customerId = "TES0";

  function auth(user) {
    let authorized = false;
    if (username === user) authorized = true
    if (username === "admin") authorized = true
    if (username === "testuser3" && user === "TES0")authorized = true
    authorized ? setIsAuth(true) : setIsAuth(false);
  }
  
  useEffect(() => {
      auth(customerId);
      setIsLoading(true)
      fetch(`https://nosapi.herokuapp.com/v1/reporting/?customerId=${customerId}`, {
        method: 'GET',
        headers: {
          'x-api-key': apiKey
        },
      })
      .then(response => {
        if (!response.ok) {
          throw new Error('Network response was not ok');
        }
        return response.json();
      })
      .then(data => {
        // Process the data from the fetch call

        // console.log(data);
        setJsonData(data);

        // Update state or perform other actions
      })
      .catch(error => {
        setIsError(true);
        console.error('There was a problem with the fetch operation:', error);
      });
      setIsLoading(false)
      
  }, [customerId, apiKey])


  

  if (isError) {
    return (
      <>
        <div className="head">
          <button style={{visibility: "hidden"}}>Reset filters</button>
          <Link to="/"><img src={logo} alt="NOS Logo" className="logo" /></Link>
          <button style={{visibility: "hidden"}}>Upload Photo</button>
        </div>
        <div className="loading-container">
          <p>Something went wrong, please try again or contact</p>
          <a href="mailto:support@nostechnology.com">support@nostechnology.com</a>
        </div>
      </>
    )
  }

  if (isLoading) {
    return (
      <>
        <div className="head">
          <button style={{visibility: "hidden"}}>Reset filters</button>
          <Link to="/"><img src={logo} alt="NOS Logo" className="logo" /></Link>
          <button style={{visibility: "hidden"}}>Upload Photo</button>
        </div>
        <div className="loading-container">
          <img src={loading} alt="Loading" className="loading-spinner" />
        </div>
      </>
    )
  } else if (isAuth && isClicked) {
    return (
      <>
        <div className="extra"></div>
      </>
    )
  } else if (isAuth) {
    return (
      <>
        <div className="head">
          <Link to={"/"}>
            <button style={{ color: "#FF9E29" }}>Back</button>
          </Link>
          <Link to="/">
            <img src={logo} alt="NOS Logo" className="logo" />
          </Link>
          <Upload />
        </div>
        <div className='card' style={{ width: '100%', overflowX: 'auto' }}>
          {jsonData && (
            <div>
              {Object.keys(jsonData).map((key, index) => (
                <div key={index}>
                  <h2>{key}</h2>
                  <table>
                    <thead>
                      <tr>
                        {Object.keys(jsonData[key][0]).map((headerKey, headerIndex) => (
                          <th key={headerIndex}>{headerKey}</th>
                        ))}
                      </tr>
                    </thead>
                    <tbody>
                      {jsonData[key].map((item, itemIndex) => (
                        <tr key={itemIndex}>
                          {Object.values(item).map((value, valueIndex) => (
                            <td key={valueIndex}>{value}</td>
                          ))}
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </div>
              ))}
            </div>
          )}
        </div>
        <Footer />
      </>
    )
  } else if (!isAuth) {
    return (
      <>
        <div className="head">
          <button style={{visibility: "hidden"}}>Reset filters</button>
          <Link to="/"><img src={logo} alt="NOS Logo" className="logo" /></Link>
          <button style={{visibility: "hidden"}}>Upload Photo</button>
        </div>
        <div className="login">
          <div className="card" style={{maxWidth: "300px", maxHeight: "80px"}}>
            <div className="header" style={{marginBottom: "15px"}}>
              <h1>Not authorized</h1>
            </div>
            <div className="authcard">
              <Link to={"/"}>
                <button style={{backgroundColor: "#FFC88D"}}>Home Page</button>
              </Link>
              <button className="loginBtn" onClick={(e) => logOut("authToken")}>Log In</button>
            </div>
          </div>
        </div>
      </>
    )
  }
}

export default Stats;
