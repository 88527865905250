import React from 'react'
import { Link, useNavigate } from 'react-router-dom';
import jwtDecode from 'jwt-decode';

export default function Footer() {
    const navigate = useNavigate();

    const authToken = document.cookie
        .split(';')
        .find(cookie => cookie.trim().startsWith('authToken='));
    const auth = authToken !== undefined;

    let decodedToken;
    if(auth) {
        decodedToken = jwtDecode(authToken);
    } else {
        decodedToken = null;
    }
    const customerId = decodedToken !== null ? decodedToken.username : null;

    function logOut(cookieName) {
        document.cookie = cookieName + "=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;";
        navigate("/login", {state: {path: "/"}});
    }

    return (
        <div className='head footer'>
            <button className="logout" onClick={(e) => logOut("authToken")}>Log Out</button>
            <p>Signed in as {customerId}</p>
            <div style={{textAlign: 'right'}}>
                <p>NOS Technology AB</p>
                <Link className="privacy" to="/privacy">Privacy Policy</Link>
            </div>
        </div>
    )
}
