
import React, { useState } from "react";
import { useLocation, useNavigate, Navigate } from "react-router-dom";
import logo from "../images/NOS-Logo-Horizontal-Color-Black.svg";

export default function Login() {
    const [username, setUsername] = useState('');
    const [password, setPassword] = useState('');
    const [fail, setFail] = useState(false);
    const [isError, setIsError] = useState(false);
    const { state } = useLocation();
    const navigate = useNavigate();

    const authToken = document.cookie
        .split(';')
        .find(cookie => cookie.trim().startsWith('authToken='));
    const auth = authToken !== undefined;
    
    if (auth) {
        return <Navigate to="/" />
    }

    const path = state.path;

    const handleFormSubmit = (event) => {
        event.preventDefault();

        handleLogin(username, password);


        // const validCredentials = [
        //   { username: 'TES0', password: 'password' },
        //   { username: 'admin', password: 'admin' },
        //   { username: 'BLA1', password: 'Harald' }
        // ];
    
        // const matchedUser = validCredentials.find(
        //   (cred) => cred.username === username && cred.password === password
        // );
    
        // if (matchedUser) {
        //   setLoggedIn(true);
        // } else {
        //   alert('Invalid username or password');
        // }
    };

    async function handleLogin(username, password) {
        try {
            const response = await fetch('/tryLogin', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify({
                    username: username,
                    password: password
                })
            });
            if (response.ok) {
                setFail(false);
                navigate(path);
            } else {
                setFail(true);
            }
        } catch (error) {
            setIsError(true);
            console.log('Error occurred:', error);
        }
    };

    if (isError) {
        return (
          <>
            <div className="head">
              <button style={{visibility: "hidden"}}>Reset filters</button>
              <img src={logo} alt="NOS Logo" className="logo" />
              <button style={{visibility: "hidden"}}>Upload Photo</button>
            </div>
            <div className="loading-container">
              <p>Something went wrong, please try again or contact</p>
              <a href="mailto:support@nostechnology.com">support@nostechnology.com</a>
            </div>
          </>
        )
      }
    
    return (
        <>
            <img src={logo} alt="NOS Logo" className="logo" />
            <form onSubmit={handleFormSubmit} className="login">
                <input
                    id="loginUsername"
                    type="text"
                    placeholder="Username..."
                    value={username}
                    onChange={(e) => {
                        setUsername(e.target.value);
                        setFail(false);
                    }}
                    autoComplete="username"
                    autoCapitalize="none"
                />
                <input
                    id="loginPassword"
                    type="password"
                    placeholder="Password..."
                    value={password}
                    onChange={(e) => {
                        setPassword(e.target.value);
                        setFail(false);
                    }}
                    autoComplete="current-password"
                    autoCapitalize="none"
                />
                <p id="loginIncorrect" style={{visibility: fail ? "visible" : "hidden"}}>Incorrect username or password</p>
                <button id="loginSubmit" type="submit">Log In</button>
            </form>
        </>
    );
}


