import React from 'react';
import logo from "../images/NOS-Logo-Horizontal-Color-Black.svg";
import { Link } from 'react-router-dom';
import Upload from './Upload';
import Footer from './Footer';

export default function Privacy() {
  return (
    <>
        <div className="head">
        <Link to={"/"}>
            <button style={{color: "#FF9E29"}}>Back</button>
        </Link>
        <Link to="/"><img src={logo} alt="NOS Logo" className="logo" /></Link>
        <Upload />
        </div>
        <div className='card' id='privacyPolicy'>
            <div className="header">
                <h1>Privacy Policy</h1>
            </div>
            <h2>Introduction</h2>
            <p>
            This Privacy Policy outlines how NOS Technology AB ("we," "us," or "our") collects, uses, discloses, and protects personal information obtained in the course of providing our business-to-business (B2B) services. We are committed to safeguarding the privacy and confidentiality of the personal information we collect.
            </p>

            <h2>Types of Information Collected</h2>
            <p>
            We may collect the following types of personal information in the context of our B2B services:
            </p>
            <ul>
            <li>Contact information (e.g., names, job titles, business addresses, phone numbers, email addresses) of individuals associated with our client organizations.</li>
            <li>Financial and billing information necessary for payment processing and invoicing.</li>
            </ul>

            <h2>Methods of Collection</h2>
            <p>
            We collect personal information from the following sources:
            </p>
            <ul>
            <li>Directly from our client organizations or their authorized representatives during the onboarding process or when providing support and services.</li>
            <li>Publicly available sources, such as business directories or professional networking platforms.</li>
            </ul>

            <h2>Purpose of Collection</h2>
            <p>
            We collect and process personal information for the following purposes:
            </p>
            <ul>
            <li>Facilitating the provision of our B2B services, including client communication, project management, and customer support.</li>
            <li>Managing contractual agreements and invoicing.</li>
            <li>Complying with legal and regulatory obligations.</li>
            <li>Performing data analytics to improve our services and business operations.</li>
            </ul>

            <h2>Legal Basis for Processing</h2>
            <p>
            Our processing of personal information is based on the following legal grounds:
            </p>
            <ul>
            <li>Contractual necessity: Processing is necessary for the performance of our contracts with client organizations.</li>
            <li>Legitimate interests: Processing is necessary for our legitimate business interests, such as providing and improving our services, ensuring the security of our systems, and complying with legal obligations.</li>
            </ul>

            <h2>Use and Disclosure of Information</h2>
            <p>
            We use personal information for the purposes stated above and may disclose it to the following parties:
            </p>
            <ul>
            <li>Our employees and authorized personnel who require access to perform their duties.</li>
            <li>Service providers and contractors who assist us in delivering our services (e.g., IT support, payment processors).</li>
            <li>Business partners or affiliates involved in delivering or enhancing our services, subject to appropriate confidentiality obligations.</li>
            <li>Law enforcement agencies, regulatory authorities, or other third parties when required by applicable laws, regulations, or legal processes.</li>
            </ul>

            <h2>Data Security Measures</h2>
            <p>
            We maintain appropriate technical and organizational measures to protect personal information against unauthorized access, loss, alteration, or destruction. These measures include, but are not limited to, encryption, access controls, firewalls, and regular security assessments. We also ensure that our employees and authorized personnel adhere to strict confidentiality obligations.
            </p>

            <h2>Data Retention</h2>
            <p>
            We retain personal information for as long as necessary to fulfill the purposes outlined in this Privacy Policy, unless a longer retention period is required or permitted by law. We regularly review our data retention practices and securely dispose of personal information that is no longer needed.
            </p>

            <h2>Cookies and Tracking Technologies</h2>
            <p>
            We may use cookies or similar tracking technologies on our website for analytics and improving user experience. By using our website, you consent to the use of such technologies. You can manage your cookie preferences through your browser settings.
            </p>

            <h2>Third-Party Links and Services</h2>
            <p>
            Our website or services may contain links to third-party websites or integrate with third-party services that have their own privacy policies. We are not responsible for the privacy practices or content of these third parties. We encourage you to review their privacy policies when accessing their websites or using their services.
            </p>

            <h2>Changes to the Privacy Policy</h2>
            <p>
            We may update this Privacy Policy from time to time to reflect changes in our practices or legal requirements. We will notify our client organizations of any material changes through appropriate means. The "Effective Date" at the top of this policy indicates when the latest version of the policy became effective.
            </p>

            <h2>Contact information</h2>
            <p>
            <a href='mailto:info@nostechnology.com'>info@nostechnology.com</a>
            </p>
        </div>
        <Footer/>
    </>
  )
}
