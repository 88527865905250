
import { useNavigate } from 'react-router-dom';
import React from 'react';
import jwtDecode from 'jwt-decode';

export default function Row({individual, report, download, currentImageIndex}) {
    const navigate = useNavigate()

    const authToken = document.cookie
        .split(';')
        .find(cookie => cookie.trim().startsWith('authToken='));
    let username = jwtDecode(authToken).username;
    let admin = username === "admin" ? true : false;

    function convertDate(date) {
        const clientDate = new Date();
        const options = { timeZone: 'UTC' };
        const utcDateString = clientDate.toLocaleString('en-US', options);
        const utcDate = new Date(utcDateString);
        
        const timeDifferenceInMinutes = (clientDate.getTime() - utcDate.getTime()) / 60000;
        const timeDifferenceInMilliseconds = timeDifferenceInMinutes * 60 * 1000;
        
        // Extract the date and time components from the date string
        const [datePart, timePart] = date.split(' ');
        const [year, month, day] = datePart.split('-');
        const [hours, minutes] = timePart.split(':');
        
        // Create a new date object with the extracted components
        const convertedDate = new Date(year, month - 1, day, hours, minutes);
        
        // Add the time difference in milliseconds to the converted date
        const finalDate = new Date(convertedDate.getTime() + timeDifferenceInMilliseconds);
        
        // Extract the adjusted components from the final date
        const adjustedYear = finalDate.getFullYear();
        const adjustedMonth = finalDate.getMonth() + 1;
        const adjustedDay = finalDate.getDate();
        const adjustedHours = finalDate.getHours();
        const adjustedMinutes = finalDate.getMinutes();
        const adjustedSeconds = finalDate.getSeconds();
        const adjustedMilliseconds = finalDate.getMilliseconds();
        
        // Format the adjusted components into a new date string
        const adjustedDateString = `${adjustedYear}-${adjustedMonth.toString().padStart(2, '0')}-${adjustedDay.toString().padStart(2, '0')} ${adjustedHours.toString().padStart(2, '0')}:${adjustedMinutes.toString().padStart(2, '0')}:${adjustedSeconds.toString().padStart(2, '0')}.${adjustedMilliseconds.toString().padStart(3, '0')}`;
        return adjustedDateString.substring(0,10)
    }
    function convertTime(date) {
        const clientDate = new Date();
        const options = { timeZone: 'UTC' };
        const utcDateString = clientDate.toLocaleString('en-US', options);
        const utcDate = new Date(utcDateString);
        
        const timeDifferenceInMinutes = (clientDate.getTime() - utcDate.getTime()) / 60000;
        const timeDifferenceInMilliseconds = timeDifferenceInMinutes * 60 * 1000;
        
        // Extract the date and time components from the date string
        const [datePart, timePart] = date.split(' ');
        const [year, month, day] = datePart.split('-');
        const [hours, minutes] = timePart.split(':');
        
        // Create a new date object with the extracted components
        const convertedDate = new Date(year, month - 1, day, hours, minutes);
        
        // Add the time difference in milliseconds to the converted date
        const finalDate = new Date(convertedDate.getTime() + timeDifferenceInMilliseconds);
        
        // Extract the adjusted components from the final date
        const adjustedYear = finalDate.getFullYear();
        const adjustedMonth = finalDate.getMonth() + 1;
        const adjustedDay = finalDate.getDate();
        const adjustedHours = finalDate.getHours();
        const adjustedMinutes = finalDate.getMinutes();
        const adjustedSeconds = finalDate.getSeconds();
        const adjustedMilliseconds = finalDate.getMilliseconds();
        
        // Format the adjusted components into a new date string
        const adjustedDateString = `${adjustedYear}-${adjustedMonth.toString().padStart(2, '0')}-${adjustedDay.toString().padStart(2, '0')} ${adjustedHours.toString().padStart(2, '0')}:${adjustedMinutes.toString().padStart(2, '0')}:${adjustedSeconds.toString().padStart(2, '0')}.${adjustedMilliseconds.toString().padStart(3, '0')}`;
        
        return adjustedDateString.substring(11,16)
    }
    function detectBerry(report) {
        let berry
        report.lingon > report.blueberries ? berry="Lingonberries" : berry="Blueberries"
        return berry
    }
    function detectContent(report) {
        let berry = detectBerry(report)
        let content = berry === "Lingonberries" ? report.lingon : report.blueberries
        if (!download) {
            content < 1 ? content = "<1 %" :
            content > 99 ? content = "> 99 %" :
            content = Math.round(content) + ' %'
        }
        return content
    }
    function detectWrong(report) {
        let berry = detectBerry(report)
        let content = berry === "Lingonberries" ? report.blueberries : report.lingon
        if (!download) {
            content < 1 ? content = "<1 %" :
            content > 99 ? content = "> 99 %" :
            content = Math.round(content) + ' %'
        }
        return content
    }
    function roundLogic(type) {
        let content = type === "waste" ? report.waste :
        type === "unripe" ? report.unripe :
        type === "unidentified" ? report.unidentified :
        "ERR: roundLogic at Row.js"
        content < 1 ? content = "<1 %" :
        content > 99 ? content = "> 99 %" :
        content = Math.round(content) + ' %'
        return content
    }
    function detectUpload(report) {
        let upload
        if (report.nosProductInfo === "{testblob}" ||
        report.nosProductInfo === "“{“Test”: {“Version”: “V1”, “ID”: “Test”, ”Location”: “IP-address”}}”" ||
        report.nosProductInfo === "{“Test”: {“Version”: “V1”, “ID”: “Test”, ”Location”: “IP-address”}}" ||
        report.nosProductInfo === "{BLOBHERE}" ||
        report.nosProductInfo === "{'Machine': {'Version': “V1”, 'SNorUser': '57b37cad', 'Location': '192.168.0.1', 'metaData': 'anyDataHere'}}") {
            upload = "N/A"
            return upload
        }
        try {
          let json = JSON.parse(report.nosProductInfo.replace(/'/g, '"'))
          if (json.web) {
              upload = "Web"
              return upload
          }
          if (json.machine || json.Machine) {
              upload = "Machine"
              return upload
          }
        } catch (error) {
          return "N/A"
        }
    }

    if (individual === "1") {
        return (
            <tr>
                <td>{detectBerry(report)}</td>
                <td>{`${convertDate(report.date)} ${convertTime(report.date)}`}</td>
                <td>{report.internalExternal}</td>
                <td>{detectUpload(report)}</td>
            </tr>
        )
    } else if (individual === "2") {
        return (
            <tr>
                <td style={currentImageIndex === 1? {color: "#ff9e29"} : {color: "#1a1a1a"}}>{detectContent(report)}</td>
                <td style={currentImageIndex === 2? {color: "#ff9e29"} : {color: "#1a1a1a"}}>{detectWrong(report)}</td>
                <td style={currentImageIndex === 3? {color: "#ff9e29"} : {color: "#1a1a1a"}}>{roundLogic("waste")}</td>
                <td style={currentImageIndex === 4? {color: "#ff9e29"} : {color: "#1a1a1a"}}>{roundLogic("unripe")}</td>
            </tr>
        )
    } else if (individual === "3") {
        return (
            <tr>
                {admin && <td>{roundLogic("unidentified")}</td>}
                {admin && <td>{report.customerId}</td>}
            </tr>
        )
    } else {
        return (
            <tr className="table-row" onClick={() => navigate(`/id/${report.id}`)}>
                <td>{`${convertDate(report.date)} ${convertTime(report.date)}`}</td>
                <td>{report.internalExternal}</td>
                <td>{detectBerry(report)}</td>
                <td>{detectContent(report)}</td>
                <td>{detectWrong(report)}</td>
                <td>{download ? report.waste : roundLogic("waste")}</td>
                <td>{download ? report.unripe : roundLogic("unripe")}</td>
                <td>{detectUpload(report)}</td>
                {admin && <td>{download ? report.unidentified : roundLogic("unidentified")}</td>}
                {admin && <td>{report.customerId}</td>}
                <td>{report.id}</td>
            </tr>
        )
    }
}
