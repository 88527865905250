import React from "react"
import { useDataContext } from '../components/DataContext';

export default function useUploadType() {
  const { activeFilters, setActiveFilters } = useDataContext()

  return {
    renderUploadType:(
    <form className="filters">
      <input
        type="radio"
        name="upload"
        id="machine"
        value="Machine"
        onChange={(e) => {
          setActiveFilters({...activeFilters, upload: e.target.value})
        }}
        defaultChecked={activeFilters.upload === "Machine"}
      />
      <label id="quad" style={{
        marginBottom: '5px',
      }} htmlFor="machine">
        Machine
      </label>
      <input
        type="radio"
        name="upload"
        id="web"
        value="Web"
        onChange={(e) => {
          setActiveFilters({...activeFilters, upload: e.target.value})
        }}
        defaultChecked={activeFilters.upload === "Web"}
      />
      <label id="quad" htmlFor="web">
        Web
      </label>
      <input
        type="radio"
        name="upload"
        id="bothUpload"
        value="both"
        onChange={(e) => {
          setActiveFilters({...activeFilters, upload: e.target.value})
        }}
        defaultChecked={activeFilters.upload === "both"}
      />
      <label id="quad" htmlFor="bothUpload">
        Both
      </label>
      <input
        type="radio"
        name="upload"
        id="compareUpload"
        value="compare"
        onChange={(e) => {
          setActiveFilters({...activeFilters, upload: e.target.value})
        }}
        defaultChecked={activeFilters.upload === "compare"}
      />
      <label id="quad" htmlFor="compareUpload">
        Compare
      </label>
    </form>
  )}
}
