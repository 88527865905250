
import React, { useState } from "react"
import { useDataContext } from "./DataContext"

export default function useDate() {
    const { activeFilters, setActiveFilters } = useDataContext()
    
    const [startDate, setStartDate] = useState(activeFilters.date[0])
    const [endDate, setEndDate] = useState(activeFilters.date[1])

    const handleStartDateChange = (e) => {
      const newStartDate = e.target.value;
      setStartDate(newStartDate);
      setActiveFilters({
        ...activeFilters,
        date: [newStartDate, endDate], // Update the date filter in activeFilters
      });
    };
  
    const handleEndDateChange = (e) => {
      const newEndDate = e.target.value;
      setEndDate(newEndDate);
      setActiveFilters({
        ...activeFilters,
        date: [startDate, newEndDate], // Update the date filter in activeFilters
      });
    };

    return {
      renderDate:(
      <form className="filters">
          <div className="date-wrapper">
            <input
              id="date1"
              type="date"
              value={startDate}
              onChange={handleStartDateChange}
              max={endDate}
            />
          </div>
          <div className="date-wrapper">
            <input
              id="date2"
              type="date"
              value={endDate}
              onChange={handleEndDateChange}
              min={startDate}
            />
          </div>
      </form>
    )}
  }
  