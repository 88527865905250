
import LineChart from "./LineChart"
import React from 'react';

export default function Result({ both, internal, external, berry, web, machine, compare, filterText }) {
  if (both.length === 0) {
    return (
      <div className="card" style={{
        overflowX: 'auto',
        height: "300px"
      }}>
        <div className="header">
          <h1>Results</h1>
          <p style={{textAlign: "center", width: "50%"}}>{filterText}</p>
          <p style={{visibility: "hidden"}}>DD MON</p>
        </div>
        <div className="no-wrapper">
          No analysis passed the filters
        </div>
      </div>
    )
  }
  const date = new Date(both[both.length-1].x);
  const options = { day: 'numeric', month: 'short' };
  const formattedDate = date.toLocaleDateString('en-US', options);
  let lastDate = new Date(both[0].x).getTime()
  let firstDate = new Date(both[both.length-1].x).getTime()
  let scale
  lastDate-firstDate < 180000 ? scale = 'second' :
  lastDate-firstDate < 10800000 ? scale = 'minute' :
  lastDate-firstDate < 259200000 ? scale = 'hour' :
  lastDate-firstDate < 1814400000 ? scale = 'day' :
  lastDate-firstDate < 7776000000 ? scale = 'week' : scale = 'month'
  let backgroundColor
  let borderColor
  if (berry === "lingon") {
    backgroundColor = "#F9DCDC"
    borderColor = "#EF3030"
  } else {
    borderColor = "#3030EF"
    backgroundColor = "#E6E6FF"
  }
  let borderWidth = 3;
  let pointRadius = 4;
  let dataset

  let bothAvg = avgArr(both)
  let internalAvg = avgArr(internal)
  let externalAvg = avgArr(external)
  let webAvg = avgArr(web)
  let machineAvg = avgArr(machine)

  function avgArr(array) {
    const dailyData = {}
    for (let i = 0; i < array.length; i++) {
      let today = new Date(array[i].x)
      let year = today.getFullYear()
      let month = today.getMonth().toString()
      if (month.toString().length === 1) month = '0' + month.toString();
      (today.getMonth()+1).toString().length === 2 ?
        month = (today.getMonth()+1) :
        month = '0' + (today.getMonth()+1).toString()
      let day
      (today.getDate()).toString().length === 2 ?
        day = (today.getDate()) :
        day = '0' + (today.getDate())
      let date = year + '-' + month + '-' + day

      if (!dailyData[date]) {
        dailyData[date] = {sum: 0, count: 0}
      }

      dailyData[date].sum += array[i].y
      dailyData[date].count += 1
    }
    
    const avg = [];
    for (const date in dailyData) {
      if (dailyData.hasOwnProperty(date)) {
        const { sum, count } = dailyData[date]
        const average = sum / count
        avg.push({x: date, y: average})
      }
    }

    return avg
  }

  // Comparison by type sender
  if (compare.compare && compare.type === 'sender') {
    dataset = [
      {
        label: "Internal",
        data: internalAvg,
        backgroundColor: '#E1FFE7',
        borderColor: '#16D316',
        borderWidth: borderWidth,
        pointRadius: pointRadius,
      },
      {
        label: "External",
        data: externalAvg,
        backgroundColor: '#FFEADC',
        borderColor: '#FF9E29',
        borderWidth: borderWidth,
        pointRadius: pointRadius,
      }
    ];
  }
  // Comparison by type uploadType
  else if (compare.compare && compare.type === 'upload') {
    dataset = [
      {
        label: "Machine",
        data: machineAvg,
        backgroundColor: '#E1FFE7',
        borderColor: '#16D316',
        borderWidth: borderWidth,
        pointRadius: pointRadius,
      },
      {
        label: "Web",
        data: webAvg,
        backgroundColor: '#FFEADC',
        borderColor: '#FF9E29',
        borderWidth: borderWidth,
        pointRadius: pointRadius,
      }
    ];
  }
  // No comparison
  else {
    dataset = [
      {
        label: "Content",
        data: bothAvg,
        backgroundColor: backgroundColor,
        borderColor: borderColor,
        borderWidth: borderWidth,
        pointRadius: pointRadius,
      }
    ];
  }

  const userData = {
    datasets: dataset
  }
  return (
    <div className="card" style={{
      overflowX: 'auto',
      height: '300px'
    }}>
      <div className="header">
        <h1>Results</h1>
        <p style={{textAlign: "center", width: "50%"}}>{filterText}</p>
        <p style={{visibility: scale !== "day" && scale !== "week" && scale !== "month" ? "visible" : "hidden"}}>{formattedDate}</p>
      </div>
      <div className="chart-wrapper">
        <LineChart chartData={userData} scale={scale}/>
      </div>
    </div>
  )
}
