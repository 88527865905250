
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import { DataProvider } from './components/DataContext';
import Home from './components/Home';
import Login from './components/Login';
import Report from './components/Report';
import ProtectedRoute from './components/ProtectedRoute';
import React from 'react';
import Privacy from './components/Privacy';
import Stats from './components/Stats';

function App() {
  return (
    <DataProvider>
    <div className='main'>
      <Router>
        <Routes>
          <Route exact path="/" element={<ProtectedRoute />}>
            <Route exact path="/" element={<Home />} />
          </Route>
          <Route exact path="/login" element={<Login />} />
          <Route exact path="/" element={<ProtectedRoute />}>
            <Route path="/id/:id" element={<Report />} />
          </Route>
          <Route exact path="/" element={<ProtectedRoute />}>
            <Route path="/privacy" element={<Privacy />} />
          </Route>

          <Route exact path="/" element={<ProtectedRoute />}>
            <Route path="/stats" element={<Stats />} />
          </Route>

        </Routes>
      </Router>
    </div>
    </DataProvider>
  );
}

export default App;
